<template>
  <section class="bg-light h-full">
    <div class="container py-8">

      <div class="text-primary">
        La buena digestión es importante para el crecimiento y desarrollo de tu hijo. El indicador más visible de la salud digestiva de tu hijo son… ¡sus heces! Así es como puedes ayudar a tu hijo a lograr unas buenas popós.
      </div>

      <div
        class="flex flex-row justify-center"
      >
        <button
          :class="{active:type=='colors'}"
          class="bg-white border-secondary text-primary px-8 py-2 rounded-full m-4
            border-2 focus:outline-none shadow-around"
          @click="selectType('colors')"
        >
          Colores
        </button>
        <button
          :class="{active:type=='shapes'}"
          class="bg-white border-secondary text-primary px-8 py-2 rounded-full m-4
            border-2 focus:outline-none shadow-around"
          @click="selectType('shapes')"
        >
          Formas
        </button>
      </div>

      <div
        v-if="type"
        class="flex flex-row justify-center"
      >
        <button
          :class="{active:attribute=='good'}"
          class="bg-white border-secondary text-primary px-8 py-2 rounded-full m-4
            border-2 focus:outline-none shadow-around"
          @click="selectAttribute('good')"
        >
          Buenos
        </button>
        <button
          :class="{active:attribute=='bad'}"
          class="bg-white border-secondary text-primary px-8 py-2 rounded-full m-4
            border-2 focus:outline-none shadow-around"
          @click="selectAttribute('bad')"
        >
          Malos
        </button>
      </div>

      <div class="py-8">
        
        <!-- NEW -->
        <div v-for="(t, k) in data" :key="k" >

          <div v-if="type == undefined || type == k">

            <div v-for="m in ['good', 'bad']" :key="`${k}-${m}`">

              <div v-if="attribute == undefined || attribute == m">
                <div class="text-primary font-display font-bold text-5xl text-center" v-html="t[m]['title']" />

                <div class="flex flex-row justify-center flex-wrap py-8">

                  <div
                    class="text-center flex flex-col justify-end p-4 w-48"
                    v-for="(item, i) in t[m]['items']"
                    :key="'item-'+i"
                  >
                    <img
                      :src="`/img/poop/results/${k}/${m}/${item['image']}.${t.type}`"
                      class="mb-4"
                    />
                    <div
                      class="text-primary text-lg text-center whitespace-nowrap"
                      v-html="item['description']"
                    />
                  </div>

                </div>

                <div class="text-primary text-lg text-center mb-8" v-html="t[m]['description']"/>

              </div>
            </div>

          </div>

        </div>
        <!-- /NEW -->

        <!-- OLD
        <div v-if="type && attribute">

          <div class="text-primary font-display font-bold text-5xl text-center" v-html="data[type][attribute]['title']" />

          <div class="flex flex-row justify-center flex-wrap py-8">

            <div
              class="text-center flex flex-col justify-end p-4 w-48"
              v-for="(item, i) in data[type][attribute]['items']"
              :key="'item-'+i"
            >
              <img
                :src="`/img/poop/results/${type}/${attribute}/${item['image']}.${data[type].type}`"
                class="mb-4"
              />
              <div
                class="text-primary text-lg text-center whitespace-nowrap"
                v-html="item['description']"
              />
            </div>

          </div>

          <div class="text-primary text-lg text-center" v-html="data[type][attribute]['description']"/>

        </div>
        /OLD -->

      </div>

    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Poop",
  setup() {

    const data = {
      colors: {
        type: "png",
        good: {
          title: "Colores Buenos",
          description: "Estos <b>colores</b> son variaciones normales.",
          items: [
            { image: "greenish-coffee", description: "Café Verdoso" },
            { image: "coffee", description: "Café" },
            { image: "coffee-yellow", description: "Café Amarillento" },
            { image: "dark-green", description: "Verde Oscuro" },
          ],
        },
        bad: {
          title: "Colores Malos",
          description: "Estos <b>colores</b> pueden indicar signos de sangrado o problemas de digestión en los crios.",
          items: [
            { image: "red", description: "Rojo Brillante" },
            { image: "black", description: "Negro" },
            { image: "white", description: "Blanco" },
            { image: "with-red-bars", description: "Con Rayas Rojas" },
          ],
        },
      },
      shapes: {
        type: "svg",
        good: {
          title: "Formas Fáciles de Defecar",
          description: "Estas son las <b>formas</b> ideales que se pueden expulsar fácilmente.",
          items: [
            { image: "spongy", description: "Elote Esponjoso" },
            { image: "sausage", description: "Salchicha Suave" },
          ],
        },
        bad: {
          title: "Formas no Adecuadas",
          description: "Estas <b>formas</b> indican signos de deshidratación, fibra insuficiente, una dieta inadecuada y/o estrés.",
          items: [
            { image: "parsley", description: "Salsa" },
            { image: "oats", description: "Como Avena" },
            { image: "grape", description: "Racimo de Uvas" },
            { image: "chicken", description: "Nuggets de Pollo" },
            { image: "rabbit", description: "Heces de Conejo" },
          ],
        },
      },
    };

    const type = ref();
    const attribute = ref();

    const selectType = (val: string) => {
      type.value = val;
    }

    const selectAttribute = (val: string) => {
      attribute.value = val;
    }

    return {
      type,
      attribute,
      selectType,
      selectAttribute,
      data,
    }
  }
});
</script>

<style scoped lang="scss">

  button.active {
    @apply bg-primary;
    @apply text-white;
    @apply font-bold;
  }

</style>
