
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Poop",
  setup() {

    const data = {
      colors: {
        type: "png",
        good: {
          title: "Colores Buenos",
          description: "Estos <b>colores</b> son variaciones normales.",
          items: [
            { image: "greenish-coffee", description: "Café Verdoso" },
            { image: "coffee", description: "Café" },
            { image: "coffee-yellow", description: "Café Amarillento" },
            { image: "dark-green", description: "Verde Oscuro" },
          ],
        },
        bad: {
          title: "Colores Malos",
          description: "Estos <b>colores</b> pueden indicar signos de sangrado o problemas de digestión en los crios.",
          items: [
            { image: "red", description: "Rojo Brillante" },
            { image: "black", description: "Negro" },
            { image: "white", description: "Blanco" },
            { image: "with-red-bars", description: "Con Rayas Rojas" },
          ],
        },
      },
      shapes: {
        type: "svg",
        good: {
          title: "Formas Fáciles de Defecar",
          description: "Estas son las <b>formas</b> ideales que se pueden expulsar fácilmente.",
          items: [
            { image: "spongy", description: "Elote Esponjoso" },
            { image: "sausage", description: "Salchicha Suave" },
          ],
        },
        bad: {
          title: "Formas no Adecuadas",
          description: "Estas <b>formas</b> indican signos de deshidratación, fibra insuficiente, una dieta inadecuada y/o estrés.",
          items: [
            { image: "parsley", description: "Salsa" },
            { image: "oats", description: "Como Avena" },
            { image: "grape", description: "Racimo de Uvas" },
            { image: "chicken", description: "Nuggets de Pollo" },
            { image: "rabbit", description: "Heces de Conejo" },
          ],
        },
      },
    };

    const type = ref();
    const attribute = ref();

    const selectType = (val: string) => {
      type.value = val;
    }

    const selectAttribute = (val: string) => {
      attribute.value = val;
    }

    return {
      type,
      attribute,
      selectType,
      selectAttribute,
      data,
    }
  }
});
